.markie-card {
  width: 100%;
  max-width: 366px;
  border: 1px solid var(--light-gray);
  background: var(--white);
  padding: 16px;
  border-radius: 5px;
  margin-left: 8px;
  margin-top: 8px;
  position: relative;
  transition: border 0.15s ease-in-out;
  cursor: pointer;
}

.markie-card:hover {
  border: 1px solid var(--dark-gray);
}

.markie-card:hover .web-button--card.web-button--primary {
  background: var(--primary-dark);
}

.markie-card-headline {
  margin-top: 16px;
  font-weight: 500;
  font-size: var(--fs-lg);
  color: var(--black);
}

.markie-card-description {
  margin-top: 4px;
  font-size: var(--fs-sm);
  color: var(--dark-gray);
  line-height: 24px;
  width: 100%;
  max-width: 85%;
}

.markie-card-content {
  margin-top: 16px;
}

.markie-state-wrapper {
  padding: 7px 6px 5px;
  border: 1px solid var(--light-gray);
  border-radius: 3px;
  background: var(--white);
}

.markie-state-wrapper + .markie-state-wrapper {
  margin-left: 4px;
}

.markie-state {
  top: -1px;
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--primary);
}

.markie-state-text {
  color: var(--dark-gray);
  margin-left: 4px;
  font-size: var(--fs-sm);
  font-weight: bold;
  line-height: 10px;
  text-transform: uppercase;
  letter-spacing: 1.15;
}

.markie-state--active {
  background: var(--green);
}
.markie-state--draft {
  background: var(--orange);
}
.markie-state--inactive {
  background: var(--red);
}
