.logo-upload-wrapper {
  position: relative;
  width: 100%;
  padding: 24px 40px;
  border: 1px dashed var(--light-gray);
  cursor: pointer;
  border-radius: 3px;
  transition: border, color 0.15s ease-in-out;
}

.logo-upload-wrapper:hover {
  border: 1px dashed var(--dark-gray);
}

.logo-upload-wrapper:hover .logo-upload-underline {
  color: var(--primary);
}

.markie-changes {
  color: var(--orange);
  font-size: var(--fs-xs);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  margin-right: 12px;
}

.markie-logo-delete {
  position: absolute;
  top: 8px;
  right: 8px;
}

.logo-upload-headline {
  position: relative;
  z-index: 1;
}

.logo-upload-subheadline {
  color: var(--dark-gray);
  margin-top: 8px;
  text-align: center;
  position: relative;
  z-index: 1;
  font-size: var(--fs-sm);
}

.logo-upload-underline {
  padding: 0 4px;
  text-decoration: underline;
  transition: color 0.15s ease-in-out;
}

.account-content-group-wrapper {
  width: 100%;
  max-width: 35%;
}

.colours-group {
  width: 100%;
  max-width: 100%;
}

.visualizer {
  position: absolute;
  top: 0;
  right: 0;
  background: #efefef;
  padding: 20px 60px 20px 65px;
  height: 321px;
  z-index: 1;
}

.logo-visualizer {
  height: 152px;
  padding: 20px 84px 20px 89px;
  width: 285px;
}
