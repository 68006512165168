.sidebar-navigation {
  position: absolute;
  top: 79px;
  width: 100%;
  height: calc(100vh - 79px);
  background: var(--white);
  padding: 40px 24px;
  border-right: 1px solid var(--light-gray);
}

.sidebar-navigation--full {
  max-width: 20%;
}

.sidebar-navigation--compact {
  max-width: 117px;
}

.sidebar-navigation-subheadline {
  padding: 0 16px;
  margin-bottom: 16px;
  color: var(--dark-gray);
  font-size: var(--fs-xs);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}

.sidebar-navigation--compact {
  align-items: center;
}

.list {
  margin-bottom: 48px;
}

.divider {
  margin: 20px 0;
}

.sidebar-navigation-toggle {
  padding: 12px 16px;
  border: 1px solid var(--light-gray);
  background: var(--white);
  cursor: pointer;
  border-radius: 3px;
  transition: border ease-in-out 0.15s;
}

.sidebar-navigation-toggle:hover {
  border: 1px solid var(--dark-gray);
}
