.account {
  margin-left: 16px;
}

.thumbnail {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1px solid var(--light-gray);
  background: var(--white);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
  transition: border ease-in-out 0.15s;
  cursor: pointer;
}

.thumbnail:hover {
  border: 1px solid var(--dark-gray);
  background: #eee;
}

.account:hover .account-modal {
  display: flex;
}

.cool-kids {
  position: relative;
  top: 2px;
  left: -1px;
}
