.add-markie-wrapper {
  margin-top: -98px;
}

.account-video-block {
  width: 100%;
}

.input--markie {
  height: 100%;
}

.uploaded-video {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 16px;
}

.remove-uploaded-video {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.upload-video-loader {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: var(--primary);
  transform-origin: left center;
  transition: transform ease-in-out 0.15s;
}

.markie-boot-code-wrapper {
  display: none;
}

.markie-boot-code-wrapper-show {
  display: flex;
}

.markie-boot-code {
  margin-top: 8px;
  margin-bottom: 0;
}

.markie-script-group {
  width: 520px;
}

.markie-script-headline {
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 4px;
}
.markie-script-description {
  color: var(--dark-gray);
  margin-top: 8px;
  font-size: var(--fs-sm);
}
