.onboarding-step {
  width: 36px;
  height: 36px;
  border: 1px solid var(--light-gray);
  background: var(--white);
  border-radius: 50%;

  font-weight: bold;
}

.onboarding-step-header {
  margin-left: 16px;
}

.onboarding-step-headline {
  margin-bottom: 4px;
}

.onboarding-step-description {
  font-size: var(--fs-sm);
  color: var(--dark-gray);
}
