.nav-list-item-wrapper {
  width: 100%;
}

.nav-list-item {
  position: relative;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid transparent;
  border-radius: 3px;
  transition: border 0.2s ease-in-out;
}
.nav-list-item-wrapper + .nav-list-item-wrapper {
  margin-top: 4px;
}
.nav-list-item-icon {
  width: 24px;
  height: 24px;
}
.nav-list-item:hover,
.nav-list-item--active {
  border: 1px solid var(--light-gray);
}
.nav-list-item:focus {
  border: 1px solid var(--dark-gray);
  outline: none;
}
.nav-list-item-label {
  font-size: var(--fs-md);
  color: var(--black);
  margin-left: 8px;
}
