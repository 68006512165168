.page-wrapper {
  width: 100%;
  height: 100%;
}

.page-wrapper--scroll {
  overflow-y: scroll;
}

.page-wrapper--noscroll {
  overflow-y: hidden;
}

.page-wrapper--light-blue {
  background: var(--light-blue);
}

.page-wrapper--black {
  background: var(--black);
}

.page-wrapper--white {
  background: var(--white);
}

.page-wrapper--primary {
  background: var(--primary);
}

.page-wrapper-children {
  margin-top: 79px;
}
