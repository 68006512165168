.account-info-group-header {
  margin-bottom: 24px;
}

.account-info-group-headline {
  font-size: var(--fs-xl);
  font-weight: 500;
}
.account-info-group-description {
  color: var(--dark-gray);
  line-height: 24px;
  margin-top: 4px;
}
