.markie-recorder-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.markie-recommendation {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;
  background: var(--white);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: var(--black);
  padding: 10px 10px;
}

.gradient {
  position: absolute;
  width: 100%;
  height: 229px;
  background: linear-gradient(180deg, rgba(11, 11, 11, 0) 0%, #0b0b0b 100%);
  z-index: 1;
}
.gradient-top {
  transform: rotate(180deg);
  height: 169px;
  left: 0;
  top: 0;
}
.gradient-bottom {
  left: 0;
  bottom: 0;
}

.markie-recorder-cirlce-button {
  position: absolute;
  background: rgba(11, 11, 11, 0.15);
  z-index: 2;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  cursor: pointer;
}
.markie-recorder-cirlce-button:hover {
  background: rgba(11, 11, 11, 0.5);
}

.markie-recorder-close {
  top: 16px;
  right: 16px;
}

.markie-recorder-settings {
  padding: 0 16px;
  width: auto;
  border-radius: 23px;
  padding: 0 16px;
  bottom: 24px;
  left: calc(50% + 115px);
  transform: translateX(-50%);
  background: rgba(11, 11, 11, 0.5);
}
.markie-recorder-settings:hover {
  background: rgba(11, 11, 11, 1);
}

.markie-recorder-screen {
  width: auto;
  border-radius: 23px;
  left: calc(50% - 215px);
  padding: 0 16px;
  bottom: 24px;
  background: rgba(11, 11, 11, 0.5);
}

.markie-recorder-screen--cancel {
  left: calc(50% - 171px);
}

.markie-recorder-screen:hover {
  background: rgba(11, 11, 11, 1);
}

.button-text {
  margin-left: 8px;
  color: var(--white);
  white-space: nowrap;
}

.camera-settings {
  position: absolute;
  bottom: 96px;
  left: calc(50%);
  transform: translateX(-50%);
  width: 320px;
  border-radius: 5px;
  background: var(--white);
  padding: 16px;
  z-index: 3;
}

.markie-recorder-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 932px;
  height: 620px;
  overflow: hidden;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.25);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  z-index: 1;
}

.markie-recorder-content-inactive {
  border: 5px solid var(--white);
}
.markie-recorder-content-active {
  border: 5px solid var(--primary);
}

.recorder-content-headline {
  font-size: var(--fs-md);
  margin-bottom: 12px;
  font-weight: 500;
  color: var(--white);
}

.recorder-content-loader-bar {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  width: 240px;
  background: rgba(255, 255, 255, 0.3);
  height: 5px;
}

.recorder-content-loader-bar-inner {
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform ease-in-out 0.15s;
  background: var(--white);
  border-radius: 3px;
}

.markie-recorder-button-wrapper {
  position: relative;
  width: 64px;
  height: 64px;
  border: 3px solid var(--white);
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
  margin-bottom: 16px;
}

.markie-video-line {
  position: absolute;
  width: 100%;
  height: 0.5px;
  background: var(--light-gray);
  z-index: 1;
  opacity: 0.25;
}

.line-horizontal-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.line-vertical-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.markie-recorder-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  cursor: pointer;
}

.markie-recorder-button-inactive {
  background: var(--white);
}
.markie-recorder-button-active {
  background: var(--primary);
}

.markie-recorder-button.markie-recorder-button-active:hover {
  background: var(--primary);
}

.markie-recorder-button:hover {
  background: var(--light-gray);
}
.markie-recorder-button-wrapper:active {
  transform: scale(1.05);
}
.markie-recorder-button-wrapper:active .markie-recorder-button {
  transform: translate(-50%, -50%) scale(0.95);
}

.markie-clip-video {
  transform-origin: center center;
  transform: scale(1.2);
}

.markie-video-display-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.markie-video-display--screen {
  max-width: 65.5%;
}

.markie-video-display--small {
  max-width: 34%;
  right: 0;
  left: auto;
}

.markie-video-display {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.markie-video-display-screenshare {
  object-fit: contain;
}

.markie-video-recorded {
  cursor: pointer;
}

.markie-video-buttons {
  position: relative;
  z-index: 1;
  margin-bottom: 72px;
  margin-right: 16px;
  width: 420px;
}

.markie-recorder-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 11, 11, 0.25);
}
