.accordion-item + .accordion-item {
  margin-top: 8px;
}

.accordion-item-header {
  background: var(--darker-gray);
  border-radius: 5px;
  padding: 4px 12px;
  cursor: pointer;
}

.accordion-item-header:hover {
  background: var(--light-gray);
}

.accordion-item-header:hover .accordion-item-chevron {
  transform: rotate(-180deg);
}

.accordion-item-headline {
  font-weight: 500;
}

.accordion-item-chevron {
  transition: transform 0.3s ease-in-out;
}
.accordion-item-chevron--active {
  transform: rotate(-180deg);
}

.accordion-item-content {
  margin-top: 8px;
}
.accordion-item-content--active {
  display: flex;
}

.accordion-item-content--inactive {
  display: none;
}

.accordion-item-chevron {
  fill: var(--black);
}
