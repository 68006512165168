.icon {
  width: 24px;
  height: 24px;
}

.text-with-icon {
  margin-left: 8px;
}

.web-button-with-dropdown-btn,
.web-button-with-dropdown-chevron {
  cursor: pointer;
}

.web-button.web-button-with-dropdown--primary {
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.25);
  border: none;
}
.web-button.web-button-with-dropdown--secondary {
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
  border: 1px solid var(--light-gray);
}

.web-button-options-list {
  position: absolute;
  top: 48px;
  right: 0;
  background: var(--white);
  width: 175px;
  border: 1px solid var(--light-gray);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.15);
  border-radius: 5px;
  z-index: 6;
  padding: 8px;
}

.web-button-options-list-item {
  padding: 4px;
  border-radius: 5px;
  color: var(--black);
  position: relative;
}

.web-button-options-list-item--activate {
  color: var(--green);
  background: #f1fef2;
}

.web-button-options-list-item--deactivate {
  color: var(--red);
  background: #fef1f1;
}

.web-button-options-list-item--hide {
  display: none !important;
}

.web-button-options-list-item:hover {
  background: var(--lightest-gray);
}

.web-button-options-list-item-icon {
  width: 24px;
  height: 24px;
}

.web-button-options-list-item-label {
  margin-left: 6px;
  font-weight: normal;
}
.web-button-options-list-item + .web-button-options-list-item {
  margin-top: 4px;
}

.web-button-with-dropdown-btn {
  border-radius: 5px 0 0 5px;
}
.web-button-with-dropdown-btn:disabled {
  background: var(--primary-dark);
}

.web-button-with-dropdown-btn--primary {
  background: var(--primary);
  border: 1px solid transparent;
}
.web-button-with-dropdown-btn--primary:hover {
  background: var(--primary-dark);
}

.web-button-with-dropdown-btn--secondary:hover {
  background: #eeeeee;
}

.web-button-with-dropdown-btn--md {
  padding: 9px 16px 8px;
}

.web-button-with-dropdown-chevron {
  border-radius: 0 5px 5px 0;
}
.web-button-with-dropdown-chevron:disabled {
  background: var(--primary-dark);
}

.web-button-with-dropdown-chevron:hover .flow-chevron-wrapper {
  transform: rotate(-180deg);
}

.flow-chevron-wrapper {
  transition: transform ease-in-out 0.3s;
}

.web-button-with-dropdown-chevron--primary {
  background: var(--primary);
  border-top: 1px solid transparent;
  border-left: 1px solid #7307df;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.web-button-with-dropdown-chevron--primary:hover {
  background: var(--primary-dark);
}
.web-button-with-dropdown-chevron--secondary:hover {
  background: #eeeeee;
}

.web-button-with-dropdown-chevron--secondary {
  border-left: 1px solid var(--light-gray);
}

.web-button-with-dropdown-chevron--md {
  padding: 6px;
}

.web-button-with-dropdown .text {
  font-weight: 500;
}
.web-button-with-dropdown--primary .text {
  color: var(--white);
}
.web-button-with-dropdown--secondary .text {
  color: var(--black);
}

.space-out-left {
  margin-left: 8px;
}
