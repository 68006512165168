.nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
}

.nav-bar {
  width: 100%;
  border-bottom: 1px solid var(--light-gray);
  background: var(--white);
  position: relative;
  z-index: 99;
}

.logo-with-nav {
  width: 100%;
  padding: 16px 24px;
  border-left: 1px solid var(--light-gray);
}
