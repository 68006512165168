.register-label-description {
  font-size: var(--fs-sm);
  color: var(--dark-gray);
  margin-bottom: 4px;
}

.attention-wrapper {
  padding: 16px;
  margin-top: 16px;
  border-radius: 5px;

  background: var(--light-yellow);
  border: 1px solid var(--dark-yellow);
  text-align: center;
  line-height: 24px;
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.1);
}
