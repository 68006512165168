.banner {
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
  border: 1px solid transparent;
}

.banner--primary {
  background: var(--primary-light);
  border: 1px solid var(--primary);
}
.banner--secondary {
  background: var(--white);
  border: 1px solid var(--light-gray);
}

.banner-message {
  margin-left: 6px;
}

.banner--vertical .banner-info-wrapper .banner-message {
  padding: 8px 0 12px;
  margin-left: 0;
  line-height: 24px;
}

.banner--primary .banner-message {
  color: var(--primary);
}
