@import "reset-css"; /* NPM Package */

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  font-family: "Matter", -apple-system, system-ui, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;

  width: 100%;
  height: 100%;

  color: var(--black);

  font-size: var(--fs-md);
}

a {
  text-decoration: none;
}

img,
video {
  width: 100%;
  max-width: 100%;
}

input,
textarea {
  font-family: "Matter", -apple-system, system-ui, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: var(--fs-md);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}

button {
  font-family: "Matter", -apple-system, system-ui, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: var(--fs-md);
  background: none;
  border: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#root {
  width: 100%;
  height: 100%;
}
