.two-factor-qr {
  width: 145px;
  height: 145px;
  padding: 4px;
  border: 1px solid var(--light-gray);
  border-radius: 5px;
}

.two-factor-form {
  width: 100%;
  margin-left: 8px;
}
