@font-face {
  font-family: "Matter";
  src: url("./assets/fonts/matter/Matter-Regular.woff2") format("woff2"),
    url("./assets/fonts/matter/Matter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Matter";
  src: url("./assets/fonts/matter/Matter-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/matter/Matter-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Matter";
  src: url("./assets/fonts/matter/Matter-Bold.woff2") format("woff2"),
    url("./assets/fonts/matter/Matter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --primary: #146aff;
  --primary-dark: #0047c2;
  --primary-light: #deeaff;
  --white: white;
  --black: #0b0b0b;
  --light-blue: #fafafa;
  --dark-gray: #a1a1a1;
  --light-gray: #e5e6e6;
  --darkest-gray: #707070;
  --darker-gray: #ededed;
  --lightest-gray: #eee;
  --red: #f81414;
  --dark-red: #b90000;
  --light-red: rgba(248, 20, 20, 0.5);
  --green: #00b900;
  --dark-green: #4db656;
  --darkest-green: #009900;
  --lightest-green: rgba(0, 185, 1, 0.5);
  --orange: #ff6600;
  --dark-yellow: #ffc000;
  --light-yellow: #fff6db;
  --fs-xxs: 10px;
  --fs-xs: 12px;
  --fs-sm: 14px;
  --fs-md: 16px;
  --fs-lg: 18px;
  --fs-xl: 22px;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.row-nowrap {
  flex-flow: row nowrap;
}

.row-wrap {
  flex-flow: row wrap;
}

.column-nowrap {
  flex-flow: column nowrap;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-self-flex-start {
  align-self: flex-start;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.web-toast {
  background: #ffffff;
  border: 1px solid var(--light-gray);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.15);
  color: var(--black);
  padding: 4px 10px;
  border-radius: 5px;
}

.web-button {
  width: auto;
  font-size: var(--fs-md);
  font-weight: 500;
  border-radius: 5px;
  line-height: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  pointer-events: all;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  transition: background 0.15s ease-in-out;
  white-space: nowrap;
}
.web-button:disabled,
.web-button--disabled {
  cursor: not-allowed;
}

.web-button--stretch {
  width: 100%;
  justify-content: center;
  text-align: center;
}

.web-button--xs {
  padding: 3px 8px;
}

.web-button--sm {
  padding: 10px 12px;
}
.web-button--sm.web-button--icon {
  padding: 8px 12px;
}

.web-button--md {
  padding: 13px 16px;
}

.web-button--md.web-button--icon {
  padding: 13px 12px;
}

.web-button--lg {
  padding: 18px 24px;
}

.web-button--primary {
  background: var(--primary);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.25);
  color: var(--white);
}

.web-button--secondary {
  background: #ffffff;
  border: 1px solid var(--light-gray);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
  color: var(--black);
}
.web-button.web-button--rounded {
  color: var(--white);
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.2);
  border-radius: 27px;
}
.web-button.web-button--rounded:focus,
.web-button.web-button--rounded:active {
  outline: none;
  border: 1px solid var(--black);
  box-shadow: 0 0 0 3px rgba(11, 11, 11, 0.1);
}
.web-button.web-button--rounded:hover {
  background: rgba(255, 255, 255, 0.1);
}

.web-button--primary-active,
.web-button--primary:focus,
.web-button--primary:active,
.web-button--secondary-active,
.web-button--secondary:focus,
.web-button--secondary:active,
.web-button--plain:focus,
.web-button--plain:active,
.input-select:focus {
  border: 1px solid var(--primary-dark);
  box-shadow: 0 0 0 3px var(--primary-light);
  outline: none;
}

.web-button--primary:hover {
  background: var(--primary-dark);
}
.web-button--primary:disabled {
  background: var(--primary-dark);
}

.web-button--secondary:hover {
  background: #eeeeee;
}
.web-button--secondary:disabled {
  background: #eeeeee;
}
.web-button--error {
  color: var(--white);
  background: var(--red);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.15);
}
.web-button--error:focus,
.web-button--error:active {
  border: 1px solid var(--dark-red);
  box-shadow: 0 0 0 3px var(--light-red);
  outline: none;
}
.web-button--error:hover {
  background: var(--dark-red);
}
.web-button--error:disabled {
  background: var(--dark-red);
}

.web-button--error-plain:hover {
  background: var(--lightest-red);
}

.web-button--plain:hover {
  background: #eeeeee;
}

.web-button--success {
  background: var(--green);
  color: var(--white);
}
.web-button--success:focus,
.web-button--success:active {
  box-shadow: 0 0 0 3px var(--lightest-green);
}

.web-button--success:hover {
  background: var(--darkest-green);
}

.web-button--hide {
  display: none;
}

.web-button--extra {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
}

.input,
.DraftEditor-root {
  padding: 6px 8px;
  border-radius: 5px;
  width: 100%;
  font-size: var(--fs-sm);
  color: var(--black);
  border: 1px solid var(--light-gray);
  font-family: inherit;
  appearance: none;
  cursor: pointer;
  line-height: 18px;
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
  background: var(--white);
  transition: border 0.15s ease-in-out;
}

.input-radio {
  width: 16px;
  height: 16px;
  background: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 50%;
  margin: 0;
  appearance: auto;
  cursor: pointer;
}
.input-radio:checked {
  background: var(--primary);
  border: 1px solid var(--primary);
}

.DraftEditor-root {
  overflow: hidden;
}

.public-DraftEditorPlaceholder-root,
.public-DraftEditorPlaceholder-hasFocus {
  color: var(--dark-gray);
}

.input:hover {
  border: 1px solid var(--dark-gray);
}

.input:disabled {
  color: var(--black);
  background: var(--light-gray);
  border: 1px solid var(--dark-gray);
  cursor: not-allowed;
}

.input--account {
  width: 100%;
  max-width: 35%;
}

.input--default {
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  box-shadow: 0 0 0 3px transparent;
  outline: none;
}
.input--focus,
.input:focus {
  width: 100%;
  border: 1px solid var(--primary);
  box-shadow: 0 0 0 3px var(--primary-light);
  border-radius: 5px;
  outline: none;
}

.input--error {
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--dark-red);
  box-shadow: 0 0 0 3px var(--light-red);
  outline: none;
}

.input-label {
  margin-bottom: 4px;
  font-weight: 500;
  cursor: pointer;
}

.input-sublabel {
  font-weight: bold;
  text-transform: uppercase;
  color: var(--dark-gray);
  font-size: var(--fs-xs);
  letter-spacing: 1px;
}

.input-label-radio {
  margin-bottom: 0;
  margin-left: 8px;
  font-weight: 400;
  color: var(--black);
}

.input-radio:checked + .input-label-radio {
  color: var(--primary);
}

.input-label--regular {
  font-weight: 400;
}
.input-label--cursor {
  cursor: pointer;
}

.input-label--account {
  width: 100%;
  max-width: 32%;
  margin-right: 8px;
  margin-bottom: 0;
}

.input-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid var(--light-gray);
  background: transparent;
  margin: 0 4px 0 0;
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
  cursor: pointer;
}

.input-checkbox:checked {
  border: 1px solid var(--primary-dark);
  background: var(--primary);
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 0 3px var(--primary-light);
}

.flow-tab-list {
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid var(--light-gray);
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
}

.flow-tab-list-item {
  display: flex;
  margin-bottom: -1px;
  padding: 0 0 16px;
  border: none;
  border-bottom: 1px solid transparent;
  background: none;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  transition: color 0.15s ease-in-out;
}

.flow-tab-list-item + .flow-tab-list-item {
  margin-left: 32px;
}

.flow-tab-list-item:hover {
  color: var(--primary);
}

.flow-tab-list-item--active {
  color: var(--primary);
  border-bottom: 2px solid var(--primary);
}

.builder-step-group + .builder-step-group {
  margin-top: 24px;
}

.builder-step-group--md + .builder-step-group--md {
  margin-top: 16px;
}

.label-option-mark {
  cursor: pointer;
  position: relative;
  margin-left: 8px;
}

.verical-ruler {
  width: 1px;
  background-color: var(--light-gray);
}

.upload-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.spacer {
  margin: 8px 0;
}
.spacer--horizontal {
  margin: 0 8px;
}
.spacer--horizontal--sm {
  margin: 0 4px;
}
.spacer--horizontal--md {
  margin: 0 12px;
}
.spacer--sm {
  margin: 4px 0;
}
.spacer--xs {
  margin: 2px 0;
}
.spacer--md {
  margin: 12px 0;
}
.spacer--lg {
  margin: 16px 0;
}

.markie-script-code {
  border-radius: 5px;
  background: var(--black);
  overflow: hidden;
  margin-bottom: 8px;
  width: 100%;
  padding: 8px;
}

.hljs {
  color: var(--white);
  line-height: 1.35;
}

.hljs-attr,
.hljs-template-tag {
  color: #8787d7;
}

.hljs-comment,
.hljs-doctag,
.hljs-quote {
  color: var(--dark-green);
}

.hljs-params {
  color: var(--darkest-gray);
}

.hljs-regexp {
  color: #d700ff;
}

.hljs-literal,
.hljs-number,
.hljs-selector-id,
.hljs-tag {
  color: var(--red);
}

.hljs-meta,
.hljs-meta .hljs-keyword {
  color: var(--primary);
}

.hljs-code,
.hljs-formula,
.hljs-keyword,
.hljs-link,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-variable {
  color: var(--primary);
}

.hljs-built_in,
.hljs-deletion,
.hljs-title {
  color: var(--orange);
}

.hljs-attribute,
.hljs-function,
.hljs-name,
.hljs-property,
.hljs-section,
.hljs-type {
  color: var(--dark-yellow);
}

.hljs-addition,
.hljs-bullet,
.hljs-meta .hljs-string,
.hljs-string,
.hljs-subst,
.hljs-symbol {
  color: var(--green);
}

.hljs-selector-tag {
  color: #96f;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 700;
}

.react-select-container {
  display: flex;
  margin-right: 8px;
  width: 100%;
  max-width: 25%;
}

.react-select-container--full {
  max-width: 100%;
}

.react-select__control {
  width: 100%;
  border: 1px solid var(--light-gray) !important;
  font-family: inherit !important;
  cursor: pointer !important;
  line-height: 18px;
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
  background: var(--white);
  min-height: 32px !important;
}

.react-select-container:hover .react-select__control {
  border: 1px solid var(--dark-gray) !important;
}

.react-select__control--is-focused {
  border: 1px solid var(--primary-dark) !important;
  box-shadow: 0 0 0 3px var(--primary-light) !important;
  outline: none !important;
}

.react-select__value-container {
  font-size: var(--fs-sm);
}

.react-select__input-container {
  margin: 0 !important;
}

.react-select__indicator-separator {
  display: none !important;
}

.react-select__indicator svg {
  width: 14px !important;
  height: 14px !important;
  fill: var(--primary);
}

.react-select__menu {
  padding: 4px 8px 4px !important;
  z-index: 100 !important;
  border-radius: 5px;
}

.react-select__option {
  color: var(--black) !important;
  border-radius: 5px !important;
  margin: 4px 0;
  cursor: pointer !important;
}

.react-select__option.react-select__option--is-focused {
  background: var(--lightest-gray) !important;
}
.react-select__option.react-select__option--is-selected {
  background: var(--primary-light) !important;
}

.mobile-not-supported {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
}

.mobile-not-supported-wrapper {
  width: 100%;
  max-width: 375px;
}
