.chip {
  color: var(--dark-gray);
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  padding: 8px 6px 7px;
  font-size: var(--fs-sm);
  font-weight: 500;
  line-height: 6px;
}

.chip--light {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(229, 230, 230, 0.35);
  color: var(--white);
}
.chip-next-text {
  padding: 0 6px;
  color: var(--dark-gray);
}

.chip--light + .chip-next-text {
  color: white;
}
