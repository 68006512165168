.analytics-table {
  width: 100%;
}

.analytics-table-headline {
  width: 100%;
  max-width: 10%;
  text-align: left;
  font-size: var(--fs-xs);
  color: var(--dark-gray);
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 0 24px;
}

.analytics-table-title {
  width: 100%;
  max-width: 45%;
}

.analytics-table-body {
  width: 100%;
}

.analytics-table-row {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--light-gray);
  border-radius: 3px;
}

.analytics-table-row:hover {
  border: 1px solid var(--dark-gray);
}

.analytics-table-row + .analytics-table-row {
  margin-top: 8px;
}

.analytics-table-data {
  padding: 4px 0;
  color: var(--dark-gray);
  text-align: left;
  width: 100%;
  max-width: 10%;
}

.analytics-table-data-bold {
  font-weight: 500;
  color: var(--black);
}

.analytics-table-title-row {
  width: 100%;
  max-width: calc(45% - 6px);
}

.analytics-table-data-action {
  align-self: flex-end;
}
