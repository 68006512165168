.onboarding-list-item-content {
  padding: 16px;
  border: 1px solid var(--light-gray);
  border-radius: 3px;
  background-color: var(--light-blue);
  width: 100%;
}

.onboarding-list-item-content-wrapper {
  width: 100%;
  max-width: 70%;
}

.onboarding-list-item-content-headline {
  font-weight: 500;
  font-size: var(--fs-lg);
  margin-bottom: 4px;
  width: 100%;
}

.onboarding-list-item-content-description {
  width: 100%;
  max-width: 70%;
  margin-bottom: 16px;
}

.onboarding-list-item-content-text {
  line-height: 22px;
}

.onboarding-list-item-content-text + .onboarding-list-item-content-text {
  margin-top: 16px;
}

.onboarding-list-item-content-subheadline {
  font-weight: 500;
}

.onboarding-list-item-content-link {
  width: 100%;
  margin-top: 8px;
}

.tutorial-thumbnail {
  height: 100%;
  max-width: 75%;
}
