.login-wrapper {
  width: 100%;
  height: 100%;
  background: var(--light-blue);
}

.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 375px;
}

.login-logo-wrapper {
  padding: 16px 0;
  width: 100%;
}

.account-form-wrapper {
  padding: 16px;
  background: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  width: 100%;
  max-width: 375px;
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
}

.account-form {
  width: 100%;
}

.account-form-header {
  padding: 16px 0 24px;
}

.account-form-headline {
  font-size: var(--fs-lg);
  font-weight: 500;
}

.account-form-description {
  margin-top: 8px;
  color: var(--dark-gray);
}

.divider {
  width: 100%;
  margin: 16px auto;
  position: relative;
}

.divider-text {
  font-size: var(--fs-xs);
  font-weight: bold;
  text-transform: uppercase;
  padding: 4px 8px;
  background: var(--white);
  position: relative;
  z-index: 1;
}

.line {
  position: absolute;
  top: calc(50%);
  left: 0;
  height: 1px;
  background: var(--light-gray);
  width: 100%;
  z-index: 0;
}

.account-form-header + .attention-wrapper {
  margin-top: -8px;
}

.signup-info-text {
  color: var(--dark-gray);
  font-size: var(--fs-sm);
  text-align: center;
  line-height: 18px;
}

.signup-info-text--link {
  color: var(--primary);
  text-decoration: underline;
  margin-left: 6px;
}

.signup-info-text--link:hover {
  text-decoration: none;
}

.attention-wrapper .signup-info-text {
  color: var(--black);
  text-align: center;
  line-height: 18px;
}

.account-form-two-factor-authentifcation {
  width: 100%;
}
