.markie-logo-m-canvas {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: var(--black);
}
.markie-logo-m {
  fill: var(--white);
}
.markie-logo-type {
  fill: var(--black);
}
