.prompt {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.prompt-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 350px;
  transform: translate(-50%, -50%);
  padding: 24px;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.prompt-headline {
  font-size: var(--fs-lg);
  font-weight: 500;
  text-align: center;
}

.prompt-text {
  text-align: center;
  color: var(--dark-gray);
  margin: 8px 0 16px;
  line-height: 24px;
}

.prompt-actions {
  width: 100%;
}

.prompt-action--half {
  width: 100%;
  max-width: 49%;
}

.background {
  width: 100%;
  height: 100%;
  background: rgba(11, 11, 11, 0.2);
}

@media only screen and (max-width: 425px) {
  .prompt-content {
    max-width: 85%;
  }
}
