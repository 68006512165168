.account-content-block {
  position: relative;
  padding: 32px 0;
  border-top: 1px solid var(--light-gray);
}

.account-content-block:first-child {
  border-top: 1px solid transparent;
}

.input-label-text--description {
  width: 100%;
  max-width: 70%;
  color: var(--dark-gray);
  margin-top: 5px;
  font-size: var(--fs-sm);
  line-height: 22px;
}
