.markie-cards {
}

.markie-cards {
  margin-top: 8px;
  margin-left: -8px;
}

.markie-cards-group + .markie-cards-header {
  margin-top: 32px;
}
