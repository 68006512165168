.account-onboarding {
  width: 100%;
}

.onboarding-wrapper {
  width: 100%;
}

.onboarding-list {
  width: 100%;
  max-width: 49%;
}

.onboarding-content {
  display: none;
}

.onboarding-content--active {
  display: flex;
  width: 100%;
  max-width: 49%;
}

.onboarding-list-item {
  border-radius: 5px;
  padding: 12px 16px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: border ease-in-out 0.15s;
  outline: none;
}

.onboarding-list-item:hover,
.onboarding-list-item--active {
  border: 1px solid var(--light-gray);
}

.onboarding-list-item:active {
  border: 1px solid var(--black);
}

.onboarding-list-item + .onboarding-list-item {
  margin-top: 8px;
}
