.account-modal {
  display: none;
  position: absolute;
  top: 62px;
  right: 24px;
  width: 100%;
  max-width: 295px;
  background: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.15);
  border-radius: 5px;
  z-index: 6;
}

.account-modal-header {
  padding: 16px;
  border-bottom: 1px solid var(--light-gray);
}
.account-modal-name {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.account-modal-list {
  padding: 8px;
  width: 100%;
}
.account-modal-list + .account-modal-list {
  border-top: 1px solid var(--light-gray);
}

.account-modal-list-item {
  width: 100%;
}

.account-modal-list-item + .account-modal-list-item {
  margin-top: 4px;
}

.info-box {
  width: 100%;
  padding: 8px;
  background: var(--primary-light);
  color: var(--primary);
  border-radius: 5px;
  font-size: var(--fs-sm);
  border: 1px solid transparent;
  cursor: pointer;
}

.info-box:hover {
  border: 1px solid var(--primary);
}
