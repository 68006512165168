.markie-visualizer {
  position: relative;
  width: 160px;
  height: 160px;
}

.markie-visualizer-wrapper {
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.25);
}

.markie-visualizer-video-wrapper {
  border-radius: 50%;
  width: 160px;
  height: 160px;
  overflow: hidden;
  border: 7px solid var(--primary);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  transition: border-radius 0.15s ease-in-out;
  cursor: pointer;
}

.markie-visualizer-video-wrapper:hover .sound-icon-wrapper {
  transform: translate(-50%, -50%) scale(1.2);
}

.markie-visualizer-video-wrapper--rectangle {
  border-radius: 15px;
}

.markie-visualizer-arrow-svg {
  position: absolute;
  top: -8px;
  left: -8px;
  transform: scale(1.35) rotate(5deg);
  transform: top left 0.15s ease-in-out;
}

.markie-visualizer-arrow-svg--rectangle {
  top: -24px;
  left: -24px;
}

.markie-visualizer-video {
  transform: scale(2);
}

.sound-icon-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  transition: transform 0.15s ease-in-out;
  z-index: 1;
}
