.account-menu-item {
  width: 100%;
  padding: 4px;
  border-radius: 5px;
  color: var(--black);
  cursor: pointer;
}
.account-menu-item:hover {
  background: var(--lightest-gray);
}

.account-menu-item-label {
  margin-left: 6px;
}
.account-menu-item-label-red {
  color: #f81414;
}

.account-menu-item-red:hover {
  background: #fef1f1;
}
