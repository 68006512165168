.loader {
}

.loader--sm {
  transform: scale(0.5);
}

.loader--light {
  opacity: 1;
}

.loader--extra-light {
  opacity: 0.6;
}

.loader-in-button {
  margin-right: 6px;
}

.loader-text {
  font-size: var(--fs-sm);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  color: var(--white);
  margin-left: 6px;
}

.loader-icon-circle {
  fill: none;
  stroke: var(--white);
  stroke-width: 3;
  opacity: 0.5;
}

.loader-icon-path {
  fill: none;
  stroke: var(--white);
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.loader--dark .loader-text {
  color: var(--black);
}

.loader--dark .loader-icon-circle {
  stroke: var(--black);
  opacity: 0.2;
}
.loader--dark .loader-icon-path {
  stroke: var(--black);
}
