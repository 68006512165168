.content-wrapper {
  width: 100%;
  padding: 48px 72px;
}

.content-wrapper--full {
  max-width: 80%;
  margin-left: 20%;
}

.content-wrapper--compact {
  max-width: calc(100% - 117px);
  margin-left: 117px;
}

.content-wrapper-header {
  margin-bottom: 4px;
}

.content-wrapper-headline {
  font-size: var(--fs-lg);
  font-weight: 500;
  line-height: 26px;
}

.content-wrapper-children {
  background: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  padding: 48px 32px 24px;
}
